import React, {useState} from 'react'
import Teaser from '../assets/elevate-bw-teaser.mp4'
import Logo from '../assets/elevatelogo_white.png'
import {SkyLightStateless} from 'react-skylight'
import {Form, Col} from 'react-bootstrap'
import axios from 'axios'

const Home = () => {
    const [modal, setModal] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [formData, setFormData] = useState({});
   
    const handleFormSubmit = e => {
        e.preventDefault();
        axios({
            method: "post",
            url: 'https://elevate.com.bd/contactform/index.php',
            headers: { "content-type": "application/json" },
            data: formData
        })
        .then(result => {
          if (result.data.sent) {
            setIsSubmit(true)
          }
        })
        .catch(error => console.log( error.message ));
    };

    const handleChange = (e, field) => {
      let value = e.target.value;
      setFormData({
        ...formData,
        [field]: value,
      });
    };
    return (
        <div className="homepage">
            <div className="bg-video-wrap">
                <video src={Teaser} loop muted autoPlay playsInline></video>
                <div className="overlay"></div>
                <div className="elevate-content">
                    <div className="logo-cont">
                        <img className="logo" src={Logo} alt=""/>
                    </div>
                    <div className="enter-cont">
                        <button style={{textTransform: 'uppercase'}} onClick={() => {setModal(true)}}>Join Waitlist</button>
                    </div>
                </div>
            </div>
            <SkyLightStateless
            className={isSubmit ? 'submittedDialog' : ''}
            isVisible={modal}
            onCloseClicked={() => {setModal(false)}}
            >
                {isSubmit ? (
                    <div className="submit-message">
                        <h4>Thank you for getting in touch with us, your information is now on our waitlist and we will get back to you once there is availability.</h4>
                    </div>
                ): (
                    <React.Fragment>
                        <h3 className="join-text" style={{textAlign: 'center', padding: '20px 25px', textTransform: 'uppercase'}}>Join Our Waitlist Today</h3>
                        <div className="form-cont">
                        <form onSubmit={e => handleFormSubmit(e)}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control required type="text" name="firstName" onChange={e => handleChange(e, 'firstName')} />
                                </Form.Group>
            
                                <Form.Group as={Col}>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control required type="text" name="lastName" onChange={e => handleChange(e, 'lastName')} />
                                </Form.Group>
                            </Form.Row>
            
                            <Form.Row>
                                <Form.Group as={Col}>
                                <Form.Label>Age</Form.Label>
                                <Form.Control required type="text" name="age" onChange={e => handleChange(e, 'age')} />
                                </Form.Group>
            
                                <Form.Group as={Col}>
                                <Form.Label>Gender</Form.Label>
                                <Form.Control required as="select" name="gender" onChange={e => handleChange(e, 'gender')}>
                                    <option value="">Choose...</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </Form.Control>
                                </Form.Group>
                            </Form.Row>
            
                            <Form.Row>
                                <Form.Group as={Col}>
                                <Form.Label>Occupation</Form.Label>
                                <Form.Control required type="text" name="occupation" onChange={e => handleChange(e, 'occupation')} />
                                </Form.Group>
            
                                <Form.Group as={Col}>
                                <Form.Label>Organization</Form.Label>
                                <Form.Control required type="text" name="organization" onChange={e => handleChange(e, 'organization')} />
                                </Form.Group>
                            </Form.Row>
            
                            <Form.Row>
                                <Form.Group as={Col}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control required type="email" name="email" onChange={e => handleChange(e, 'email')} />
                                </Form.Group>
            
                                <Form.Group as={Col}>
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control required type="text" name="phone" onChange={e => handleChange(e, 'phone')} />
                                </Form.Group>
                            </Form.Row>
            
                            <Form.Group>
                                <Form.Label>Referred By (optional)</Form.Label>
                                <Form.Control placeholder="" name="refer" onChange={e => handleChange(e, 'refer')} />
                            </Form.Group>
            
                            <Form.Group>
                                <Form.Label>Special Requests (optional)</Form.Label>
                                <Form.Control as="textarea" name="request" placeholder="" onChange={e => handleChange(e, 'request')} />
                            </Form.Group>
            
                            <button type="submit" style={{textTransform: 'uppercase'}} className="submit-btn">
                                Submit
                            </button>
                        </form>
                        </div>
                    </React.Fragment>
                )}
            </SkyLightStateless>
        </div>
    )
}

export default Home
